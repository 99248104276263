import { ArItem, IBEPassengerDetail, Journey, SearchingDetail, TripDetail } from "@hkexpressairwayslimited/ui";
import { createAction, createSlice } from "@reduxjs/toolkit";
import { PassengerKey } from "[lang]/flight-booking/detail/definition";
import { PromotionDetail } from "lib/features/flight-book/flight-select/definition";
import { PaymentMetaData, PaymentMethod, PaymentPageDetail } from "lib/features/flight-book/payment/definition";
import { remove } from "lodash";
import { RootState } from "store";
import { EArItemType, EBaggageArCode, SelectableArItem } from "ui/features/flight-booking/add-extras/d";

export type SelectedArItems = {
  tripId?: string;
  journeyKey?: string;
  segmentKey?: string;
  arItems: ArItem[];
};

export type AddExtrasPageMetaData = {
  baggageSelected: {
    [paxId: string]: SelectableArItem[];
  };
  confirmedItems: Partial<Record<EArItemType, boolean>>;
  selectedArItems: SelectedArItems[];
};

export type AgentProfile = {
  name: string;
  user_name: string;
  email_address: string;
  contact_phone: string | null;
  credit_account: {
    accountReference: string;
    amount: number;
    currencyCode: string;
    foreignAmount: number;
    foreignCurrencyCode: string;
  };
};

export interface FlightBookingReducerProps<C = undefined> {
  isLogin: boolean;
  entered: boolean;
  agentToken?: AgentToken | undefined;
  agentProfile?: AgentProfile;
  searchingDetail?: SearchingDetail;
  passengerDetail?: IBEPassengerDetail<C>;
  tripDetail?: TripDetail;
  stepperIndex: number;
  addExtrasPageMetaData: AddExtrasPageMetaData;
  passengerKey?: PassengerKey[];
  paymentMetaData?: PaymentMetaData;
  paymentPageDetail: PaymentPageDetail;
  promotionDetail?: PromotionDetail;
  paymentMethod?: PaymentMethod;
}

const initialState: FlightBookingReducerProps = {
  isLogin: false,
  entered: false,
  agentToken: undefined,
  agentProfile: undefined,
  stepperIndex: 0,
  searchingDetail: undefined,
  tripDetail: undefined,
  passengerDetail: undefined,
  addExtrasPageMetaData: {
    baggageSelected: {},
    confirmedItems: {},
    selectedArItems: [],
  },
  passengerKey: undefined,
  paymentMetaData: undefined,
  paymentPageDetail: {},
  promotionDetail: undefined,
};

export type AgentToken = {
  token: string;
  timeout: string;
  idleTimeoutSecond: number;
};

const NAME = "B2B";

export const INIT_AGENT_TOKEN = `${NAME}/initAgentToken`;
export const initAgentToken = createAction<AgentToken>(INIT_AGENT_TOKEN);

export const B2BSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    enteredB2b: (state) => {
      state.entered = true;
    },
    exitB2b: (state) => {
      state.entered = false;
    },
    setAgentToken: (state, action) => {
      state.agentToken = action.payload;
    },
    setAgentProfile: (state, action) => {
      state.isLogin = true;
      state.agentProfile = action.payload;
    },
    clearAgentToken: (state) => {
      state.isLogin = false;
      state.agentToken = undefined;
      state.agentProfile = undefined;
    },
    storeFlightSearching: (state, action) => {
      state.searchingDetail = action.payload;
    },
    storeTripDetail: (state, action) => {
      state.tripDetail = action.payload;
    },
    storeTripDetailSalesReference: (state, action) => {
      if (state.tripDetail) {
        state.tripDetail.salesReference = action.payload;
      }
    },
    storePassengerDetail: (state, action) => {
      state.passengerDetail = action.payload;
      state.tripDetail?.journeys.forEach((e, index) => {
        if (index === 0) {
          const SMSpaxID =
            e.arItems.available.find((e) => e?.type === EArItemType.SMS)?.ssr_by_passengers?.at(0)?.passenger_key ?? "";
          remove(e.arItems.selected, (n) => n.type === EArItemType.SMS);
          if (action.payload.sendSMSitinerary === true) {
            e.arItems.selected.push({
              type: EArItemType.SMS,
              code: "SMS",
              byPax: [
                {
                  paxId: SMSpaxID,
                  amount: 1,
                },
              ],
            });
          }
        }
      });
    },
    updateStepperActive: (state, action) => {
      state.stepperIndex = action.payload;
    },
    updateJourney: (state, { payload }) => {
      state.tripDetail && (state.tripDetail.journeys = payload);
    },
    removeTripDetail: (state, action) => {
      if (state.tripDetail) {
        state.tripDetail.journeys = state.tripDetail?.journeys.filter((journey) => {
          return !action.payload.includes(journey.journey_key);
        });
      }
    },
    pushJourney: (state, action) => {
      state.tripDetail && state.tripDetail.journeys[action.payload.index === 1 ? "push" : "unshift"](action.payload);
    },
    resetState: (state, action) => {
      state.stepperIndex = action.payload?.stepperIndex ?? initialState.stepperIndex;
      state.passengerDetail = initialState.passengerDetail;
      state.tripDetail = initialState.tripDetail;
      state.addExtrasPageMetaData = initialState.addExtrasPageMetaData;
      state.paymentMetaData = initialState.paymentMetaData;
      state.paymentPageDetail = initialState.paymentPageDetail;
      state.passengerKey = initialState.passengerKey;
    },
    updateBaggage: (state, { payload: { journeyKey, selectedBaggage, paxId, active, tripId } }) => {
      const currentItem = state.addExtrasPageMetaData.selectedArItems.find(
        (e) =>
          e.tripId === tripId &&
          e.journeyKey === journeyKey &&
          e.segmentKey === undefined &&
          e.arItems.find((n) => n.type === EArItemType.BAGGAGE)
      );
      if (currentItem) {
        const activeOption = currentItem.arItems.find((e) => e.code === selectedBaggage.code);
        if (activeOption) {
          const activeUserSection = activeOption.byPax?.find((e) => e.paxId === paxId);
          if (activeUserSection) {
            activeUserSection.amount =
              selectedBaggage.code === EBaggageArCode._0KG
                ? active
                  ? 0
                  : 1
                : activeUserSection.amount + (active ? -1 : 1);
          } else {
            activeOption?.byPax?.push({
              paxId: paxId,
              amount: active ? 0 : 1,
            });
          }
        } else {
          currentItem.arItems.push({
            type: selectedBaggage.type,
            code: selectedBaggage.code,
            byPax: [
              {
                paxId: paxId,
                amount: active ? 0 : 1,
              },
            ],
          });
        }
        if (!active) {
          if (selectedBaggage.code === EBaggageArCode._0KG) {
            const options = currentItem.arItems.filter((e) => e.code !== selectedBaggage.code);
            options?.forEach((item) => {
              const userSection = item.byPax?.find((e) => e.paxId === paxId);
              if (userSection) {
                userSection.amount = 0;
              }
            });
          } else {
            const zeroOption = currentItem.arItems.find((e) => e.code === EBaggageArCode._0KG);
            if (zeroOption) {
              const userSection = zeroOption.byPax?.find((e) => e.paxId === paxId);
              if (userSection) {
                userSection.amount = 0;
              }
            }
          }
        }
      } else {
        state.addExtrasPageMetaData.selectedArItems.push({
          tripId,
          journeyKey,
          arItems: [
            {
              type: selectedBaggage.type,
              code: selectedBaggage.code,
              byPax: [
                {
                  paxId: paxId,
                  amount: active ? 0 : 1,
                },
              ],
            },
          ],
        });
      }
    },
    confirmSelectedBaggage: (state, { payload }) => {
      if (state.tripDetail) {
        payload?.forEach(
          (e: {
            journeyKey: string;
            arItems: ConcatArray<{
              type: string;
              code: string;
              price?: number | undefined;
              currency_code?: string | undefined;
              availability?: number | null | undefined;
              cms_content_key?: string | undefined;
              ssr_by_passengers?: { ssr_key: string; price: number; passenger_key: string }[] | undefined;
              is_pre_order?: boolean | undefined;
              is_vegetarian?: boolean | undefined;
              byPax?: { paxId: string; amount: number }[] | undefined;
              arMetaData?: { [x: string]: string | number | boolean } | undefined;
              unit_key?: string | undefined;
            }>;
          }) => {
            state.tripDetail?.journeys.forEach((m) => {
              if (m.journey_key === e.journeyKey) {
                remove(m.arItems.selected, (e) => e.type === EArItemType.BAGGAGE);
                m.arItems.selected = m.arItems.selected.concat(e.arItems);
              }
            });
          }
        );
      }
    },
    updateUFirst: (state, { payload: { passengers, added, journeyKey, code, tripId } }) => {
      remove(
        state.addExtrasPageMetaData.selectedArItems,
        (e) =>
          e.arItems.find((n) => n.type === EArItemType.U_FIRST) &&
          e.tripId === tripId &&
          e.journeyKey === journeyKey &&
          e.segmentKey === undefined
      );
      if (!added) {
        state.addExtrasPageMetaData.selectedArItems.push({
          tripId,
          journeyKey,
          arItems: [
            {
              type: EArItemType.U_FIRST,
              code,
              byPax: passengers.map((e: { id: any }) => ({
                paxId: e.id,
                amount: 1,
              })),
            },
          ],
        });
      }
    },
    confirmUFirst: (state, { payload }) => {
      if (state.tripDetail) {
        state.tripDetail?.journeys.forEach((m) => {
          remove(m.arItems.selected, (e) => e.type === EArItemType.U_FIRST);
          if (payload) {
            m.arItems.selected = m.arItems.selected.concat(
              payload.find((e: { journeyKey: string }) => e.journeyKey === m.journey_key)?.arItems ?? []
            );
          }
        });
      }
    },
    updateSIMCard: (state, { payload: { added, paxId, code, tripId } }) => {
      const currentItem = state.addExtrasPageMetaData.selectedArItems.find(
        (e) =>
          e.tripId === tripId &&
          e.journeyKey === undefined &&
          e.segmentKey === undefined &&
          e.arItems.find((n) => n.type === EArItemType.SIM_CARD)
      );
      if (currentItem) {
        const arItem = currentItem.arItems.find((e) => e.type === EArItemType.SIM_CARD);
        if (arItem?.byPax) {
          if (added) {
            remove(arItem.byPax, (e) => e.paxId === paxId);
          } else {
            arItem.byPax.push({
              paxId: paxId,
              amount: 1,
            });
          }
        } else {
          if (!added)
            currentItem.arItems.push({
              type: EArItemType.SIM_CARD,
              code,
              byPax: [
                {
                  paxId: paxId,
                  amount: 1,
                },
              ],
            });
        }
      } else {
        state.addExtrasPageMetaData.selectedArItems.push({
          tripId,
          arItems: [
            {
              type: EArItemType.SIM_CARD,
              code,
              byPax: [
                {
                  paxId,
                  amount: 1,
                },
              ],
            },
          ],
        });
      }
    },
    confirmSIMCard: (state, { payload }) => {
      if (state.tripDetail) {
        remove(state.tripDetail.arItems.selected, (e) => e.type === EArItemType.SIM_CARD);
        payload?.forEach(
          (e: {
            tripId: string;
            arItems: ConcatArray<{
              type: string;
              code: string;
              price?: number | undefined;
              currency_code?: string | undefined;
              availability?: number | null | undefined;
              cms_content_key?: string | undefined;
              ssr_by_passengers?: { ssr_key: string; price: number; passenger_key: string }[] | undefined;
              is_pre_order?: boolean | undefined;
              is_vegetarian?: boolean | undefined;
              byPax?: { paxId: string; amount: number }[] | undefined;
              arMetaData?: { [x: string]: string | number | boolean } | undefined;
              unit_key?: string | undefined;
            }>;
          }) => {
            if (state.tripDetail && e.tripId === state.tripDetail?.id) {
              state.tripDetail.arItems.selected = state.tripDetail.arItems.selected.concat(e.arItems);
            }
          }
        );
      }
    },
    updateSport: (state, { payload: { tripId, journeyKey, added, paxId, code } }) => {
      const currentItem = state.addExtrasPageMetaData.selectedArItems.find(
        (e) =>
          e.tripId === tripId &&
          e.journeyKey === journeyKey &&
          e.segmentKey === undefined &&
          e.arItems.find((n) => n.type === EArItemType.SPORT_EQUIPMENT)
      );
      if (currentItem) {
        const arItem = currentItem.arItems.find((e) => e.type === EArItemType.SPORT_EQUIPMENT);
        if (arItem?.byPax) {
          if (added) {
            remove(arItem.byPax, (e) => e.paxId === paxId);
          } else {
            arItem.byPax.push({
              paxId: paxId,
              amount: 1,
            });
          }
        } else {
          if (!added)
            currentItem.arItems.push({
              type: EArItemType.SPORT_EQUIPMENT,
              code,
              byPax: [
                {
                  paxId: paxId,
                  amount: 1,
                },
              ],
            });
        }
      } else {
        state.addExtrasPageMetaData.selectedArItems.push({
          tripId,
          journeyKey,
          arItems: [
            {
              type: EArItemType.SPORT_EQUIPMENT,
              code,
              byPax: [
                {
                  paxId,
                  amount: 1,
                },
              ],
            },
          ],
        });
      }
    },
    confirmSport: (state, { payload }) => {
      if (state.tripDetail) {
        state.tripDetail.journeys.forEach((e) => {
          remove(e.arItems.selected, (e) => e.type === EArItemType.SPORT_EQUIPMENT);
          payload?.forEach(
            (m: {
              tripId: string;
              journeyKey: string;
              arItems: ConcatArray<{
                type: string;
                code: string;
                price?: number | undefined;
                currency_code?: string | undefined;
                availability?: number | null | undefined;
                cms_content_key?: string | undefined;
                ssr_by_passengers?: { ssr_key: string; price: number; passenger_key: string }[] | undefined;
                is_pre_order?: boolean | undefined;
                is_vegetarian?: boolean | undefined;
                byPax?: { paxId: string; amount: number }[] | undefined;
                arMetaData?: { [x: string]: string | number | boolean } | undefined;
                unit_key?: string | undefined;
              }>;
            }) => {
              if (state.tripDetail && m.tripId === state.tripDetail?.id && m.journeyKey === e.journey_key) {
                e.arItems.selected = e.arItems.selected.concat(m.arItems);
              }
            }
          );
        });
      }
    },
    updateAddExtrasPageMetaData: (state, action) => {
      const { baggageSelected, confirmedItems } = action.payload;
      if (baggageSelected) {
        Object.keys(baggageSelected).forEach(
          (e) => (state.addExtrasPageMetaData.baggageSelected[e] = baggageSelected[e])
        );
      }
      if (confirmedItems) {
        state.addExtrasPageMetaData.confirmedItems = {
          ...state.addExtrasPageMetaData.confirmedItems,
          ...confirmedItems,
        };
      }
    },
    updatePaxMeal: (
      state,
      { payload: { tripId, selectedJourneyKey, selectedSegmentKey, paxId, mealCode, amount } }
    ) => {
      const currentItem = state.addExtrasPageMetaData.selectedArItems.find(
        (e) =>
          e.journeyKey === selectedJourneyKey &&
          e.segmentKey === selectedSegmentKey &&
          e.tripId === tripId &&
          e.arItems.find((n) => n.type === EArItemType.MEAL)
      );
      if (currentItem) {
        const mealSection = currentItem.arItems.find((e) => e.code === mealCode);
        if (mealSection) {
          const mealSelectedSection = mealSection.byPax?.find((e) => e.paxId === paxId);
          if (mealSelectedSection) {
            mealSelectedSection.amount = amount;
          } else {
            mealSection.byPax?.push({
              paxId,
              amount,
            });
          }
        } else {
          currentItem.arItems.push({
            type: EArItemType.MEAL,
            code: mealCode,
            byPax: [
              {
                paxId,
                amount,
              },
            ],
          });
        }
      } else {
        state.addExtrasPageMetaData.selectedArItems.push({
          tripId,
          journeyKey: selectedJourneyKey,
          segmentKey: selectedSegmentKey,
          arItems: [
            {
              type: EArItemType.MEAL,
              code: mealCode,
              byPax: [
                {
                  paxId,
                  amount,
                },
              ],
            },
          ],
        });
      }
    },
    confirmPaxMeal: (state, { payload }) => {
      if (payload) {
        payload?.forEach(
          (e: {
            journeyKey: string;
            segmentKey: string;
            arItems: ConcatArray<{
              type: string;
              code: string;
              price?: number | undefined;
              currency_code?: string | undefined;
              availability?: number | null | undefined;
              cms_content_key?: string | undefined;
              ssr_by_passengers?: { ssr_key: string; price: number; passenger_key: string }[] | undefined;
              is_pre_order?: boolean | undefined;
              is_vegetarian?: boolean | undefined;
              byPax?: { paxId: string; amount: number }[] | undefined;
              arMetaData?: { [x: string]: string | number | boolean } | undefined;
              unit_key?: string | undefined;
            }>;
          }) => {
            state.tripDetail?.journeys.forEach((n) => {
              if (n.journey_key === e.journeyKey) {
                n.segments.forEach((m) => {
                  if (m.segment_key === e.segmentKey) {
                    remove(m.arItems.selected, (e) => e.type === EArItemType.MEAL);
                    m.arItems.selected = m.arItems.selected.concat(e.arItems);
                  }
                });
              }
            });
          }
        );
      } else {
        state.tripDetail?.journeys.forEach((n) => {
          n.segments.forEach((m) => {
            remove(m.arItems.selected, (e) => e.type === EArItemType.MEAL);
          });
        });
        remove(state.addExtrasPageMetaData.selectedArItems, (n) => n.arItems.find((e) => e.type === EArItemType.MEAL));
      }
    },
    addSegmentSelectedSeat: (state, action) => {
      state.tripDetail?.journeys.forEach((journey) => {
        const segmentIndex = journey.segments.findIndex((segment) => segment.segment_key === action.payload.segmentKey);
        if (segmentIndex > -1) {
          let seatFlag = false;
          journey.segments[segmentIndex].arItems.selected.forEach((arItem) => {
            if (arItem.type === "SEAT" && arItem.byPax && arItem.byPax[0].paxId === action.payload.paxId) {
              arItem.code = action.payload.seatDesignator;
              arItem.price = action.payload.seatPrice;
              arItem.unit_key = action.payload.unitKey;
              arItem.currency_code = action.payload.currency_code;
              seatFlag = true;
            }
          });
          if (!seatFlag) {
            journey.segments[segmentIndex].arItems.selected.push({
              type: "SEAT",
              code: action.payload.seatDesignator,
              price: action.payload.seatPrice,
              unit_key: action.payload.unitKey,
              currency_code: action.payload.currency_code,
              byPax: [
                {
                  paxId: action.payload.paxId,
                  amount: 1,
                },
              ],
            });
          }
        }
      });
    },
    removeSegmentSelectedSeat: (state, action) => {
      state.tripDetail?.journeys.forEach((journey) => {
        const segmentIndex = journey.segments.findIndex((segment) => segment.segment_key === action.payload.segmentKey);
        if (segmentIndex > -1) {
          const arItems = journey.segments[segmentIndex].arItems.selected.filter((arItem) => {
            return !(arItem.type === "SEAT" && arItem.byPax && arItem.byPax[0].paxId === action.payload.paxId);
          });
          journey.segments[segmentIndex].arItems.selected = arItems;
        }
      });
    },
    storePassengerKey: (state, action) => {
      state.passengerKey = action.payload;
    },
    storePaymentMetaData: (state, action) => {
      state.paymentMetaData = action.payload;
    },
    storePaymentPageDetail: (state, action) => {
      state.paymentPageDetail = {
        ...state.paymentPageDetail,
        ...action.payload,
      };
    },
    storeTripArItems: (state, action) => {
      if (state.tripDetail?.arItems) {
        state.tripDetail.arItems.available = [...state.tripDetail.arItems.available, ...action.payload];
      }
    },
    updateInsurance: (state, action) => {
      const validPax = state.passengerKey?.filter((e) => e.type === "ADT");
      if (
        state.tripDetail &&
        action.payload &&
        action.payload === 1 &&
        !state.tripDetail.arItems.selected.find((e) => e.type === EArItemType.INSURANCE)
      ) {
        state.tripDetail.arItems.selected.push({
          type: EArItemType.INSURANCE,
          code: EArItemType.INSURANCE,
          byPax: validPax?.map((e) => ({
            amount: 1,
            paxId: e.passenger_key,
          })),
        });
      } else if (state.tripDetail && action.payload !== 1) {
        state.tripDetail.arItems.selected = state.tripDetail?.arItems.selected.filter(
          (e) => e.type !== EArItemType.INSURANCE
        );
      }
    },
    updateCarbonOffset: (state, action) => {
      if (state.tripDetail && action.payload === true && state.passengerKey) {
        state.tripDetail.arItems.selected.push({
          type: EArItemType.CARBON_OFFSET,
          code: EArItemType.CARBON_OFFSET,
          byPax: [
            {
              amount: 1,
              paxId: state?.passengerKey[0].passenger_key,
            },
          ],
        });
      } else if (state.tripDetail && action.payload === false) {
        state.tripDetail.arItems.selected = state.tripDetail?.arItems.selected.filter(
          (e) => e.type !== EArItemType.CARBON_OFFSET
        );
      }
    },
    storePromotionDetail: (state, action) => {
      state.promotionDetail = action.payload;
    },
    updatePaymentPageDetail: (state, action) => {
      if (state.paymentPageDetail) {
        Object.keys(action.payload).forEach((e) => {
          state.paymentPageDetail[e as keyof PaymentPageDetail] = action.payload[e];
        });
      }
    },
    storeEPaymentFee: (state, action) => {
      if (state.paymentMetaData) {
        state.paymentMetaData.selected_e_payment_fee = action.payload;
      }
    },
    storePaymentMethod: (state, action) => {
      state.paymentMethod = action.payload;
    },
  },
  selectors: {
    selectIsLogin: (storage: FlightBookingReducerProps) => storage.isLogin,
    selectEntered: (storage: FlightBookingReducerProps) => storage.entered,
    selectAgentToken: (storage: FlightBookingReducerProps) => storage.agentToken,
    selectAgentProfile: (storage: FlightBookingReducerProps) => storage.agentProfile,
    selectStepperActive: (storage: FlightBookingReducerProps): number => storage.stepperIndex,
    selectSearchingDetail: (storage: FlightBookingReducerProps): SearchingDetail | undefined => storage.searchingDetail,
    selectTripDetail: (storage: FlightBookingReducerProps): TripDetail | undefined => storage.tripDetail,
    selectTripJourneys: (storage: FlightBookingReducerProps): Journey[] | undefined => storage.tripDetail?.journeys,
    selectAddExtrasPageMetaData: (storage: FlightBookingReducerProps): AddExtrasPageMetaData | undefined =>
      storage.addExtrasPageMetaData,
    selectPassengerDetail: <C = undefined>(storage: FlightBookingReducerProps<C>): IBEPassengerDetail<C> | undefined =>
      storage.passengerDetail,
    selectPassengerKey: (storage: FlightBookingReducerProps) => storage.passengerKey,
    selectJourneyFlightStatus: (storage: FlightBookingReducerProps): Journey[] | undefined => {
      const flightStatusData: Journey[] = [];
      if (storage.tripDetail?.journeys) {
        storage.tripDetail.journeys.forEach((journey) => {
          flightStatusData.push({
            journey_key: journey.journey_key,
            segments: journey.segments,
            fare: journey.fare,
            origin: journey.origin,
            display_item_amount: journey.display_item_amount,
            destination: journey.destination,
            date: journey.date,
            flight_time: journey.flight_time,
            index: journey.index,
            duration: journey.duration,
            arItems: {
              available: [],
              selected: [],
              purchased: [],
            },
            arrival_date_time: journey.arrival_date_time,
            departure_date_time: journey.departure_date_time,
          });
        });
        return flightStatusData;
      }
      return undefined;
    },
    selectFares: (storage: FlightBookingReducerProps) =>
      storage.tripDetail?.journeys ? storage.tripDetail?.journeys?.flatMap((journey) => journey.fare) : [],
    selectInsuranceQuote: (storage: FlightBookingReducerProps) => storage.paymentMetaData?.insurance_quote,
    selectCarbonQuote: (storage: FlightBookingReducerProps) => storage.paymentMetaData?.carbon_quote,
    selectPaymentOptionList: (storage: FlightBookingReducerProps) => storage.paymentMetaData?.payment_option_list,
    selectPaymentMetaData: (storage: FlightBookingReducerProps) => storage.paymentMetaData,
    selectPaymentPageDetail: (storage: FlightBookingReducerProps) => storage.paymentPageDetail,
    selectPromotionDetail: (storage: FlightBookingReducerProps) => storage.promotionDetail,
    selectPaymentOrganizationCreditAccount: (storage: FlightBookingReducerProps) =>
      storage.paymentMetaData?.organization_credit_account,
    selectPaymentMethod: (storage: FlightBookingReducerProps) => storage.paymentMethod,
  },
});

export const {
  enteredB2b,
  exitB2b,
  setAgentToken,
  setAgentProfile,
  clearAgentToken,
  storeFlightSearching,
  storeTripDetail,
  storeTripDetailSalesReference,
  storePassengerDetail,
  updateStepperActive,
  updateJourney,
  removeTripDetail,
  pushJourney,
  resetState,
  updateBaggage,
  confirmSelectedBaggage,
  updateUFirst,
  updateSIMCard,
  updateSport,
  updateAddExtrasPageMetaData,
  updatePaxMeal,
  addSegmentSelectedSeat,
  removeSegmentSelectedSeat,
  storePassengerKey,
  storePaymentMetaData,
  storePaymentPageDetail,
  confirmPaxMeal,
  confirmSIMCard,
  confirmSport,
  confirmUFirst,
  storeTripArItems,
  updateInsurance,
  updateCarbonOffset,
  storePromotionDetail,
  updatePaymentPageDetail,
  storeEPaymentFee,
  storePaymentMethod,
} = B2BSlice.actions;
export default B2BSlice.reducer;
export const {
  selectIsLogin,
  selectEntered,
  selectAgentToken,
  selectAgentProfile,
  selectStepperActive,
  selectSearchingDetail,
  selectTripDetail,
  selectTripJourneys,
  selectAddExtrasPageMetaData,
  selectPassengerDetail,
  selectPassengerKey,
  selectJourneyFlightStatus,
  selectFares,
  selectInsuranceQuote,
  selectCarbonQuote,
  selectPaymentOptionList,
  selectPaymentMetaData,
  selectPaymentPageDetail,
  selectPromotionDetail,
  selectPaymentOrganizationCreditAccount,
  selectPaymentMethod,
} = B2BSlice.getSelectors((state: RootState) => {
  return state.session.B2B;
});
